<script setup lang="ts">
useHeadCommon()
</script>
<template>
  <div class="layout layout-default">
    <slot name="header"> <Header /></slot>
    <main class="main">
      <slot />
    </main>
    <slot name="footer"> <Footer /> </slot>
  </div>
</template>
